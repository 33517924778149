import { PERMISSIONS } from './permissions';

export default [
  {
    id: 'option1',
    labelStringId: 'option1',
    path: '/option1',
    icon: 'fa fa-usd',
    permission: PERMISSIONS.PERM_1,
  },
];
