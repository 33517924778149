import React, { useState, useMemo, useCallback } from 'react';
import styled from '@emotion/styled';
import { Checkbox, TableCell, TableRow as MaterialTableRow } from '@mui/material';
import cn from 'classnames';
import { Icon } from '../../common';
import { TableRowStyles } from './style';
import { dateOptions } from './tableConfig';
import TableRowDetail from './tableRowDetail';
import { formattedDateUS } from '../../../util';

const Styled = styled(MaterialTableRow)`
  ${TableRowStyles}
`;

const TableRow = ({ data }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const date = useMemo(() => formattedDateUS(new Date(data.dispatchDateTime), dateOptions), [data.dispatchDateTime]);

  const handleClickToggler = useCallback(() => {
    setIsDetailOpen(prev => !prev);
  }, []);

  return (
    <>
      <Styled
        className={cn({
          billable: data.isBillable,
          voided: data.isVoided,
          'detail-open': isDetailOpen,
        })}
      >
        <TableCell padding="checkbox">
          <div className="first-cell-inner">
            <Checkbox
              color="primary"
              inputProps={{
                'aria-labelledby': 'billable',
              }}
            />
            <button className="detail-toggler-button" onClick={handleClickToggler}>
              <Icon iconWeight="fa" iconType={isDetailOpen ? 'arrowUpLine' : 'arrowDownLine'} />
            </button>
          </div>
        </TableCell>
        <TableCell>
          <div className={cn('icon-container')}>
            <Icon
              className={cn('state', {
                billable: data.isBillable,
              })}
              iconWeight="fa"
              iconType={data.isBillable ? 'check' : 'ban'}
            />
          </div>
        </TableCell>
        <TableCell>
          {data.notes && (
            <div className={cn('icon-container')}>
              <Icon className={cn('notes')} iconWeight="fa" iconType="bookmark" />
            </div>
          )}
        </TableCell>
        <TableCell>
          <p>{date}</p>
        </TableCell>
        <TableCell>
          <p>{data.orderId}</p>
        </TableCell>
        <TableCell>
          <p>{data.ticketId}</p>
        </TableCell>
        <TableCell>
          <p>{data.projectName}</p>
        </TableCell>
        <TableCell>
          <p>{data.customerName}</p>
        </TableCell>
        <TableCell>
          <p>{data.locationName}</p>
        </TableCell>
        <TableCell>
          <p>{data.vehicleTypeName}</p>
        </TableCell>
        <TableCell>
          <p>{data.productName}</p>
        </TableCell>
      </Styled>
      {isDetailOpen ? <TableRowDetail data={data.lineItems || []} isVoided={data.isVoided} /> : null}
    </>
  );
};

export default TableRow;
