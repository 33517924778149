import React from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FilterButtonStyles } from './style';
import Icon from '../../common/Icon';

const Styled = styled.button`
  ${FilterButtonStyles}
`;

const ButtonFilter = ({
  isEmpty,
  activeFilter = false,
  handleClickFilter,
  nameFilter,
  message,
  iconName,
  isDropdown,
}) => {
  return (
    <Styled disabled={!activeFilter && isEmpty} isActive={activeFilter} onClick={() => handleClickFilter(nameFilter)}>
      <Icon className={cn('symbol')} iconWeight="fa-solid" iconType={iconName} />
      <FormattedMessage id={message.id} defaultMessage={message.defualt} />
      {isDropdown && <Icon iconWeight="fa-solid" iconType="arrowDown" />}
    </Styled>
  );
};

export default ButtonFilter;
