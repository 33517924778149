import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px', color: '#c9c9c9' }}>
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default Loader;
