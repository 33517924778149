import { useQuery } from '@tanstack/react-query';
import billables from '../queries/billables';

export const useBillables = (entityRef, date) => {
  return useQuery({
    queryFn: () => billables.searchBillables({ entityRef, date }),
    queryKey: ['billing-exp', entityRef],
    staleTime: Infinity,
  });
};
