import { createTheme } from '@mui/material/styles';
import { elevation, pxToRem } from '../mixins';
import { aqua, blue, darkBlue, green, neutral, red, yellow } from '../colors';
import { shadow, background } from '../elevation';

// TODO: check theme extension possibility to avoid repetition for components overrides (like spacing, effects)
const theme = createTheme({
  // Colors to use in component styles, values might change between theme mode (dark, light)
  customColorsComponents: {
    SearchBar: {
      color: neutral['50'],
      border: neutral['50'],
      boxShadow: elevation(shadow.light['2']),
      focus: blue['50'],
      icon: neutral['30'],
    },
    FilterButton: {
      background: neutral['100'],
      border: neutral['50'],
      boxShadow: elevation(shadow.light['1']),
      color: neutral['30'],
      icon: aqua['30'],
      active: {
        background: aqua['30'],
        border: aqua['30'],
        color: neutral['100'],
        icon: yellow['40'],
      },
      disabled: neutral['80'],
    },
    Table: {
      color: neutral['40'],
      colorFooter: neutral['0'],
      headerElevation: elevation({
        shadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
        background: '#f8fafd',
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3))',
      }),
      footerElevation: elevation({
        shadow: '0px -1px 3px 1px rgba(0, 0, 0, 0.15)',
        background: '#f8fafd',
        filter: 'drop-shadow(0px -1px 2px rgba(0, 0, 0, 0.3))',
      }),
      backgroundIsVoided: '#FFF6F7',
      icons: {
        note: blue['50'],
        isBillable: green['60'],
        nonBillable: red['60'],
      },
      DetailRow: {
        Table: {
          background: neutral['100'],
          border: neutral['40'],
          color: neutral['40'],
        },
      },
    },
  },
  elevation: {
    1: elevation({ shadow: shadow.light['1'], background: background.light['1'] }),
    2: elevation({ shadow: shadow.light['2'], background: background.light['2'] }),
    3: elevation({ shadow: shadow.light['3'], background: background.light['3'] }),
    4: elevation({ shadow: shadow.light['4'], background: background.light['4'] }),
    5: elevation({ shadow: shadow.light['5'], background: background.light['5'] }),
  },

  palette: {
    mode: 'light',
    text: {
      primary: neutral['40'],
    },
    primary: {
      main: neutral['100'],
      contrastText: neutral['40'],
    },
    secondary: {
      main: blue['10'],
      contrastText: neutral['100'],
    },
    error: {
      main: red['40'],
      contrastText: neutral['100'],
    },
  },
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: `1px solid ${neutral['40']}`,
          background: neutral['100'],
          boxShadow: '0px 4px 20px rgba(101, 119, 136, 0.2)',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderColor: neutral['50'],
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: darkBlue['50'],
          '&.Mui-checked': {
            color: darkBlue['50'],
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Work Sans',
    fontSize: 14,
    pxToRem: pxToRem,
  },
});

export default theme;
