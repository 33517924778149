import { css } from '@emotion/react';

export const ContainerStyles = ({ theme }) => css`
  border-radius: 10px;
  width: 94%;
  max-height: 700px;
  margin: 20px 0;

  & .MuiTable-root {
    min-width: 650px;
  }
`;

export const HeaderTableStyles = ({ theme }) => css`
  ${theme.customColorsComponents.Table.headerElevation}
  position: sticky;
  top: 0;
  z-index: 2;
  height: 59px;
  border-bottom-width: 1px;
  border-bottom-style: solid;

  p {
    color: ${theme.customColorsComponents.Table.color};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    margin: 0;
    text-transform: uppercase;
  }
`;

export const FooterTableStyles = ({ theme }) => css`
  ${theme.customColorsComponents.Table.footerElevation};
  position: sticky;
  bottom: 0;
  height: 59px;
  border-top-width: 1px;
  border-top-style: solid;

  & p {
    color: ${theme.customColorsComponents.Table.colorFooter};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    line-height: 4px;
    margin-right: 34px;
    margin-bottom: 0;
    text-align: right;
  }
`;

export const TableRowStyles = ({ theme }) => css`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  height: 74px;

  &.voided {
    background: ${theme.customColorsComponents.Table.backgroundIsVoided};
  }

  &.detail-open {
    border-bottom: none;
  }

  td {
    border-bottom: none;

    .first-cell-inner {
      display: flex;
      align-items: center;

      width: 80px;

      .detail-toggler-button {
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
    }

    .icon-container {
      text-align: center;
    }

    .icon {
      &.state {
        color: ${theme.customColorsComponents.Table.icons.nonBillable};
        font-size: 20px;
      }

      &.billable {
        color: ${theme.customColorsComponents.Table.icons.isBillable};
      }

      &.notes {
        color: ${theme.customColorsComponents.Table.icons.note};
        font-size: 18px;
      }
    }

    p {
      color: ${theme.customColorsComponents.Table.color};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      white-space: nowrap;
    }
  }
`;

export const TableRowDetailStyles = ({ theme }) => css`
  /* border-bottom: 1px solid ${theme.customColorsComponents.Table.border}; */

  &.voided {
    background: ${theme.customColorsComponents.Table.backgroundIsVoided};
  }

  > tr {
    > td {
      border-bottom: none;
    }
  }

  table {
    background-color: ${theme.customColorsComponents.Table.DetailRow.Table.background};
    border: 1px solid ${theme.customColorsComponents.Table.DetailRow.Table.border};
    border-radius: 10px;
    border-collapse: separate;
    border-spacing: 0;

    tr {
      > th,
      > td {
        white-space: nowrap;
        border: 1px solid ${theme.customColorsComponents.Table.DetailRow.Table.border};
      }
    }

    thead {
      tr {
        th {
          padding: 15px 10px;
          color: ${theme.customColorsComponents.Table.DetailRow.Table.color};
          font-weight: 600;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 10px 10px;
          color: ${theme.customColorsComponents.Table.DetailRow.Table.color};
          font-weight: 500;
        }
      }
    }
  }
`;
