import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import BillingFilters from './billing-filters';
import BillingSearch from './billing-search';
import BillingTable from './billing-table';
import Loader from '../common/Loader';
import { useBillables } from '../../api/hooks/billables';

const Billing = () => {
  const { entityRef } = useParams();
  const { isLoading, data, error } = useBillables(entityRef, 'Yesterday');
  const [isEmpty, setIsEmpty] = useState(!!data?.length);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    isBillable: false,
    isVoided: false,
  });

  const filteredData = useMemo(() => {
    if (!data) return [];
    let newData = [...data];

    if (filters.isBillable) {
      newData = newData.filter(row => row.isBillable);
    } else if (filters.isVoided) {
      newData = newData.filter(row => row.isVoided);
    }

    if (searchTerm) {
      const splitedSearch = searchTerm.split(' ');
      newData = newData.filter(item => {
        return Object.keys(item).some(key => {
          if (
            [
              'orderId',
              'ticketId',
              'projectName',
              'customerName',
              'locationName',
              'vehicleTypeName',
              'productName',
            ].includes(key)
          ) {
            return splitedSearch.every(term => item[key].toLowerCase().includes(term.toLowerCase()));
          }
          return false;
        });
      });
    }
    setIsEmpty(!!newData?.length);

    return newData;
  }, [data, filters, searchTerm]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <BillingSearch searchTerm={searchTerm} onSearchChange={setSearchTerm} />
      <BillingFilters isEmpty={!isEmpty} filters={filters} onFilterChange={setFilters} />
      <BillingTable data={filteredData} error={error} />
    </>
  );
};

export default Billing;
