import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import ButtonFilter from './ButtonFilter';

import { FiltersContainerStyles } from './style';

const Styled = styled.div`
  ${FiltersContainerStyles}
`;

const BillingFilters = ({ isEmpty, onFilterChange, filters }) => {
  const handleClickFilter = useCallback(
    filterName => {
      const filterActions = {
        billableFilter: () =>
          onFilterChange(prevFilters => ({
            ...prevFilters,
            isBillable: !prevFilters.isBillable,
            isVoided: false,
          })),
        voidedFilter: () =>
          onFilterChange(prevFilters => ({
            ...prevFilters,
            isVoided: !prevFilters.isVoided,
            isBillable: false,
          })),
      };

      if (filterActions[filterName]) {
        filterActions[filterName]();
      }
    },
    [onFilterChange]
  );

  const filterButtons = [
    {
      isActive: false,
      message: {
        id: 'billableTableFilterPriced',
        message: 'Priced',
      },
      iconName: 'dollar',
      activeFilter: false,
      isDropdown: true,
    },
    {
      isActive: false,
      message: {
        id: 'billableTableFilterTaxed',
        message: 'Taxed',
      },
      iconName: 'dollar',
      activeFilter: false,
      isDropdown: true,
    },
    {
      isActive: filters.isBillable,
      message: {
        id: 'billableTableFilterBillable',
        message: 'Billable',
      },
      handleClickFilter: handleClickFilter,
      filterId: 'billableFilter',
      iconName: 'dollar',
      nameFilter: 'billableFilter',
      activeFilter: filters.isBillable,
      isDropdown: true,
    },
    {
      isActive: filters.isVoided,
      message: {
        id: 'billableTableFilterVoided',
        message: 'Voided',
      },
      handleClickFilter: handleClickFilter,
      filterId: 'voidedFilter',
      iconName: 'dollar',
      nameFilter: 'voidedFilter',
      activeFilter: filters.isVoided,
      isDropdown: false,
    },
    {
      isActive: false,
      message: {
        id: 'billableTableFilterAdditional',
        message: 'Additional charges',
      },
      iconName: 'addCharges',
      activeFilter: false,
      isDropdown: false,
    },
    {
      isActive: false,
      message: {
        id: 'billableTableFilterInvoiced',
        message: 'Invoiced',
      },
      iconName: 'invoce',
      activeFilter: false,
      isDropdown: false,
    },
  ];

  return (
    <Styled>
      {filterButtons.map((button, index) => (
        <ButtonFilter key={index} {...button} isEmpty={isEmpty} handleClickFilter={handleClickFilter} />
      ))}
    </Styled>
  );
};

export default BillingFilters;
