export const dateOptions = {
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
};

export const billingHeaders = [
  { headName: 'Checkbox' },
  { headName: 'Billable' },
  { headName: 'Note' },
  { headName: 'Date' },
  { headName: 'Order' },
  { headName: 'Ticket' },
  { headName: 'Project' },
  { headName: 'Customer' },
  { headName: 'Location' },
  { headName: 'Vehicle Type' },
  { headName: 'Product' },
];
