import { css } from '@emotion/react';

export const EmptyStateStyles = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 146px;

  & strong,
  & p {
    font-size: 36px;
    line-height: 68px;
    text-align: center;
  }
  & p {
    font-size: 27px;
    line-height: 50px;
  }
`;
