import React from 'react';
import { useIntl } from 'react-intl';
import { InputAdornment, IconButton, InputBase } from '@mui/material';
import styled from '@emotion/styled';
import cn from 'classnames';
import { SearchBarStyles } from './style';
import Icon from '../../common/Icon';

const Styled = styled(InputBase)`
  ${SearchBarStyles}
`;

const BillingSearch = ({ searchTerm, onSearchChange }) => {
  const intl = useIntl();

  const handleChange = e => {
    const maxLength = 50;
    const value = e.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

    if (value.length <= maxLength && alphanumericRegex.test(value)) {
      onSearchChange(value);
    }
  };

  return (
    <Styled
      type="text"
      value={searchTerm}
      onChange={handleChange}
      placeholder={intl.formatMessage({ id: 'billableTableSearchPlaceholder', defaultMessage: 'Search' })}
      startAdornment={
        <InputAdornment position="start">
          <Icon iconWeight="fa-regular" iconType="search" />
        </InputAdornment>
      }
      endAdornment={
        searchTerm && (
          <InputAdornment position="end">
            <IconButton aria-label="clean search" onClick={() => onSearchChange('')} edge="end">
              <Icon className={cn('clean')} iconWeight="fa-regular" iconType="empty" />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

export default BillingSearch;
