import { css } from '@emotion/react';

export const SearchBarStyles = ({ theme, isType }) => css`
  width: 290px;
  height: 48px;
  border: 1px solid ${theme.customColorsComponents.SearchBar.border};
  box-shadow: ${theme.customColorsComponents.SearchBar.boxShadow};
  border-radius: 10px;
  font-size: 16px;
  padding: 12px 15px;
  margin-bottom: 28px;
  &::placeholder {
    color: ${theme.customColorsComponents.SearchBar.color};
  }
  &.Mui-focused {
    border: 2px solid ${theme.customColorsComponents.SearchBar.focus};
  }
  & .MuiInputBase-input {
    height: 100%;
    padding: 0 8px;
  }
  & .MuiInputAdornment-root {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
  & .icon {
    font-size: 18px;
    color: ${theme.customColorsComponents.SearchBar.icon};
    &.clean {
      font-size: 20px;
    }
  }
`;
