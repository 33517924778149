import React from 'react';
import { useIntl } from 'react-intl';
import styled from '@emotion/styled';
import { Container } from '@mui/material';
import { EmptyStateStyles } from './style';
import noResultsImage from '../../../assets/images/no_results.png';
import emptySearchImage from '../../../assets/images/search_results.png';

const Styled = styled(Container)`
  ${EmptyStateStyles}
`;

const BillingEmpty = ({ noResults }) => {
  const intl = useIntl();

  const messageInfo = {
    noResults: {
      image: noResultsImage,
      highlight: intl.formatMessage({
        id: 'billableTableNoResultHighlight',
        defaultMessage: 'All caught up on bills!',
      }),
      text: intl.formatMessage({ id: 'billableTableNoResultsText', defaultMessage: 'You’ve accomplished a lot' }),
    },
    emptySeach: {
      image: emptySearchImage,
      highlight: intl.formatMessage({ id: 'billableTableEmptySearchHighlight', defaultMessage: 'No results found' }),
      text: intl.formatMessage({ id: 'billableTableEmptySearchText', defaultMessage: 'Try a different search query' }),
    },
  };

  return (
    <Styled sx={{ width: '94%' }}>
      <figure>
        <img
          src={noResults ? messageInfo.noResults.image : messageInfo.emptySeach.image}
          alt={noResults ? messageInfo.noResults.highlight : messageInfo.emptySeach.highlight}
        />
      </figure>
      <strong>{noResults ? messageInfo.noResults.highlight : messageInfo.emptySeach.highlight} </strong>
      <p>{noResults ? messageInfo.noResults.text : messageInfo.emptySeach.text} </p>
    </Styled>
  );
};

export default BillingEmpty;
