import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { API, ConnexDesignSystem, Core } from 'connex-cds';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import messages from './i18n';
import AuthenticatedRoutes from './views/authenticated-routes/AuthenticatedRoutes';
import { RedirectToEntity } from './views/authenticated-routes/RedirectToEntity';
import ThemeProvider from './theme/ThemeProvider';
import { Landing } from './views/landing/Landing';
import { OptionOne } from './views/option-1/OptionOne';

API.setSource('billing-exp');

const ONE_HOUR = 1000 * 60 * 60;
const TWELVE_HOURS = ONE_HOUR * 12;

const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 5000, cacheTime: TWELVE_HOURS, refetchOnWindowFocus: false } },
});

const App = () => {
  const [isConfiguring, setIsConfiguring] = React.useState(true);

  React.useEffect(() => {
    API.initialize().finally(() => {
      setIsConfiguring(false);
    });
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Core.Spinner spin={isConfiguring}>
          <ConnexDesignSystem messages={messages} appId="billing-exp" breakpoints={[950]}>
            <ThemeProvider>
              <Routes>
                <Route path="" element={<RedirectToEntity />}>
                  <Route path="/:entityRef/*">
                    <Route path="" element={<AuthenticatedRoutes />}>
                      <Route path="" element={<Landing />} />
                      <Route path="option1" element={<OptionOne />} />
                    </Route>
                  </Route>
                </Route>
              </Routes>
            </ThemeProvider>
          </ConnexDesignSystem>
        </Core.Spinner>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
