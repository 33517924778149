import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { Table, TableBody, TableContainer } from '@mui/material';
import TableHeader from './tableHeader';
import TableFooterRecords from './tableFooter';
import TableRow from './tableRow';
import { ContainerStyles } from './style';
import { billingHeaders } from './tableConfig';
import BillingEmpty from '../billing-empty';

const Styled = styled(TableContainer)`
  ${ContainerStyles}
`;

const BillingTable = ({ data, error }) => {
  const [visibleRows, setVisibleRows] = useState(8);
  const tableContainerRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const tableContainer = tableContainerRef.current;
      if (tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight) {
        setVisibleRows(prevRows => prevRows + 8);
      }
    };

    const tableContainer = tableContainerRef.current;

    if (tableContainer) tableContainer.addEventListener('scroll', handleScroll);
    return () => {
      if (tableContainer) tableContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onSelectAllClick = event => console.log(event);

  if (error) {
    return <p>Error: {error}</p>;
  }
  if (!data.length) {
    return <BillingEmpty noResults={!data?.length} />;
  }

  return (
    <Styled ref={tableContainerRef}>
      <Table aria-label="simple table">
        <TableHeader headers={billingHeaders} onSelectAllClick={onSelectAllClick} />
        <TableBody>
          {data.slice(0, visibleRows).map(row => (
            <TableRow key={row.crn} data={row} />
          ))}
        </TableBody>
        <TableFooterRecords records={data.length} columns={11} />
      </Table>
    </Styled>
  );
};

export default BillingTable;
