import React from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import { TableCell, TableRow } from '@mui/material';
import { Icon } from '../../common';
import { TableRowDetailStyles } from './style';

const Styled = styled(TableRow)`
  ${TableRowDetailStyles}
`;

const TableRowDetail = ({ isVoided, data }) => {
  return (
    <Styled
      className={cn({
        voided: isVoided,
      })}
    >
      <TableCell colSpan={11}>
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th>ID/Description</th>
              <th>Unit Price</th>
              <th>Pricing</th>
              <th>Ordered Quantity</th>
              <th>Taxable</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map(lineItem => (
              <tr key={lineItem.productRef}>
                <td>{lineItem.productName}</td>
                <td>{lineItem.productId}</td>
                <td>{`$ ${lineItem.unitPrice}`}</td>
                <td>{lineItem.priceSource}</td>
                <td>{`${lineItem.quantity} ${lineItem.uomCode}`}</td>
                <td>{`${lineItem.isTaxable ? 'YES' : 'NO'}`}</td>
                <td>{`$ ${lineItem.totalPrice}`}</td>
                <td>
                  <Icon iconWeight="fa" iconType="trash" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableCell>
    </Styled>
  );
};

export default TableRowDetail;
