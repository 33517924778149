const elevation = ({ shadow, background = '', filter = '' }, type = 'string') => {
  if (type === 'object') {
    return {
      background: background,
      boxShadow: shadow,
      filter: filter,
    };
  }
  return `
    ${background ? `background: ${background};` : ''};
    box-shadow: ${shadow};
    ${filter ? `filter: ${filter}` : ''};
  `;
};

const pxToRem = value => `${value / 16}rem`;

export { elevation, pxToRem };
