import React from 'react';
import styled from '@emotion/styled';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { HeaderTableStyles } from './style';

const Styled = styled(TableHead)`
  ${HeaderTableStyles}
`;

const TableHeader = ({ headers, onSelectAllClick }) => {
  return (
    <Styled>
      <TableRow>
        {headers.map((header, index) =>
          header.headName === 'Checkbox' ? (
            <TableCell key={index} padding="checkbox" sx={{ borderBottom: 'none' }}>
              <Checkbox
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all billables',
                }}
              />
            </TableCell>
          ) : (
            <TableCell key={index} sx={{ borderBottom: 'none' }}>
              <p>{header.headName}</p>
            </TableCell>
          )
        )}
      </TableRow>
    </Styled>
  );
};

export default TableHeader;
