import styled from '@emotion/styled';
import React from 'react';
import cn from 'classnames';
import style from './style';

const Styled = styled.div`
  ${style}
`;

export const OptionOne = props => {
  return <Styled className={cn('option-one')}>Menu Option 1</Styled>;
};
