import React from 'react';
import styled from '@emotion/styled';
import { TableCell, TableFooter, TableRow } from '@mui/material';
import { FooterTableStyles } from './style';

const Styled = styled(TableFooter)`
  ${FooterTableStyles}
`;

const TableFooterRecords = ({ records, columns }) => {
  return (
    <Styled>
      <TableRow>
        <TableCell colSpan={columns}>
          <p> {`Showing ${records} records`} </p>
        </TableCell>
      </TableRow>
    </Styled>
  );
};

export default TableFooterRecords;
