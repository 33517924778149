import styled from '@emotion/styled';
import React from 'react';
import Billing from '../../components/billing';

const Styled = styled.div``;

export const Landing = () => {
  return (
    <Styled>
      <Billing />
    </Styled>
  );
};
