import { css } from '@emotion/react';

export const FiltersContainerStyles = () => css`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const FilterButtonStyles = ({ theme, isActive }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  background: ${isActive
    ? theme.customColorsComponents.FilterButton.active.background
    : theme.customColorsComponents.FilterButton.background};
  border: 1px solid
    ${isActive
      ? theme.customColorsComponents.FilterButton.active.border
      : theme.customColorsComponents.FilterButton.border};
  border-radius: 8px;
  box-shadow: ${theme.customColorsComponents.FilterButton.boxShadow};
  color: ${isActive
    ? theme.customColorsComponents.FilterButton.active.color
    : theme.customColorsComponents.FilterButton.color};
  cursor: pointer;
  white-space: nowrap;

  .symbol {
    color: ${isActive
      ? theme.customColorsComponents.FilterButton.active.icon
      : theme.customColorsComponents.FilterButton.icon};
  }

  &:disabled {
    color: ${theme.customColorsComponents.FilterButton.disabled};
    border-color: ${theme.customColorsComponents.FilterButton.disabled};
    pointer-events: none;
    .icon,
    .symbol {
      color: ${theme.customColorsComponents.FilterButton.disabled};
    }
  }
`;
